
.homepage-banner-img {
    background-repeat: no-repeat;
    z-index: 9999;
    height: 100vh;
    width: -webkit-fill-available;
    background-position: top right;
    background-image: url("../img/homepage/Group-67.png");
}

.vegan-card-layout {
    background: #003F61;
    border-radius: 60px 0 0 0;
    max-width: 100%;
}

.accelerate-title {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 77px;
    letter-spacing: 0;
    z-index: 99;
    position: absolute;
    top: -40px;
    left: 0;
    text-align: center;
    width: 100%;
    color: #ffffff;
}

.accelerate-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 39px;
    font-style: normal;
    font-weight: 400;
    padding: 30px 150px;
    color: #ffffff;
    line-height: 47px;
    letter-spacing: 0;
    text-align: center;
}

.accelerate-card-img img {
    object-fit: cover;
    min-height: 413px;
    border-radius: 0 40px;
}

.accelerate-card {
    background-color: #5db77a;
    padding: 130px 10px 120px
}

.vegan-card-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: #ffffff;
    margin: 20px 0;
}

.home-banner-text {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 44px;
    margin-bottom: 40px;
    color: #fff;
    line-height: 53px;
}

.home-buttons
{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    color: white !important;
    text-transform: none !important;
    padding: 10px 20px;
}

.home-button:hover, .home-buttons:focus{
    color: white !important;
}

.vegan-card-title {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    margin-bottom:22px;
    margin-top:22px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: left;

}


.vegan-card-title-2 {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    text-align: left;
    color:#ffffff;
}

/*.vegan-card-width {*/
/*    padding: 20px 0;*/
/*    display: table-caption;*/
/*    caption-side: bottom;*/
/*}*/

.vegan-cards > div{
    margin: 0 auto;
    display: block;
    width:70%;
}

.vegan-card-width {
    padding: 20px 0;
    /*display: table-caption;*/
    /*caption-side: bottom;*/
}

.card-bottom-border {
    background: #E0806F;
    margin-top: 15px;
    border-radius: 4px;
    height: 5px;
    display: inline-flex;
    line-height: 75px;
    flex-direction: column-reverse;
}

.about-title {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: white;
    line-height: 45px;
}

.about-subtitle {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #003f61;
    text-align: left;
}

.about-bottom-card {
    border-radius: 0 40px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #003f61;
    margin-bottom: -8rem;
    position: relative;
}

.about-text-para {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: white;
    text-align: left;

}

.card-bottom-border-white {
    border-bottom: 5px solid #ffffff;
    margin-top: 15px;
    border-radius: 4px;
}

.font-weight-500 {
    font-weight: 500;
}

.video-home-Wrapper {
    position: relative;
    padding-bottom: 56.2%;
    /*padding-bottom: 90%;*/
}


.video-home-Wrapper video {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 0 40px;
}

.bottom-drawer .MuiDrawer-paper
{
    border-radius: 20px 20px 0 0;
    padding:10px;
}

.home-card-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    color: #ffffff;
    margin: 20px 0;
}

.home-bg-img {
    background-repeat: no-repeat;
    background-image: url("../img/homepage/Bg1.png");
    background-size: contain;
    background-position: left;
    margin-top: -80px;
    position: relative;
}


.main-bg-background {
    background-repeat: no-repeat;
    background-image: url("../img/homepage/Background.png");
    background-size: contain;
    background-position: left;
    height: 550px;
    margin-top: -80px;
    position: relative;

}

.header-title {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0;
    color: white;
}

.header-para {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: white;
}

.header-card-section {
    height: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header-section {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
}

.header-small-section {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
}

.card-position-section {
    position: relative;
    left: 0;
    top: -190px;
    background: #003F61;
    border-radius: 0 40px;
    width: 100%;
    margin-bottom: -160px;
}

.card-position-up-section {
    position: relative;
    left: 0;
    top: -350px;
    background: #003F61;
    border-radius: 0 40px;
    width: 100%;
    margin-bottom: -320px;
}


.about-us-section {
    margin-top: 350px;
}

.header-card-bottom {
    margin-bottom: -7em;
}

.about-bg {
    background-repeat: no-repeat;
    background-image: url("../img/aboutus/about-bg.png");
    background-size: cover;
    background-position: right;
    height: auto;
    margin-bottom: 18px;
}

.video-bg-img {
    background-repeat: no-repeat;
    background-image: url("../img/homepage/Bg.png");
    background-size: contain;
    background-position: right;
}

.vegan-img-border {
    border-radius: 0 40px;
}

.about-cards > div{
    margin: 20px auto;
    display: block;
    width:85%;
}

.subscription-popup .MuiDialog-scrollPaper {
    display: flex;
    align-items: flex-end !important;
    justify-content: center;
}

.subscription-popup .MuiDialog-paper {
    margin: 20px 20px 70px 20px !important;
}

.appBar-menu {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.appBar-menu:hover, .appBar-menu:focus {
    background-color: unset !important;
    color: #5db77a !important;
}

.hr-line {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #c3c1c1;
    line-height: 0.1em;
}

.hr-line span {
    background:#fff;
    padding:0 10px;
}

@media (max-width: 1300px) {
    .header-card-section {
        height:unset;
    }
}

@media (max-width: 992px) {
    .homepage-banner-img {
        background-position: 0 30%;
        background-size: contain;
    }

    .about-cards > div{
        margin: 0 auto;
        display: block;
        width:100%;
    }

    .vegan-card-layout img {
        border-radius: 40px 0 !important;
    }

    .home-banner-text {
        font-family: 'HK Grotesk', sans-serif;
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
    }

    .vegan-cards > div{
        margin: 0 auto;
        display: block;
        width:100%;
    }

}

@media (max-width: 767px) {
    .homepage-banner-img {
        background-position: 0 30%;
        background-size: contain;
    }



    .home-bg-img,.main-bg-background {
        margin-top: -100px;
    }

    .vegan-card-layout img {
        border-radius: 0 40px !important;
    }

    .vegan-card-layout {
        background: transparent;
        border-radius: 60px 0 0 0;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .vegan-card-layout-sm img {
        border-radius: 40px 0 !important;
    }


    .vegan-card-layout-sm {
        background: #003F61;
        border-radius: 60px 0 0 0;
        max-width: 100%;
    }

    .card-position-section {
        height: unset;
    }

    .header-card-section {
        height: unset;
    }

    .accelerate-title {

        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        top: -85px;
        text-align: center;

    }

    .video-bg-img {
        background-repeat: no-repeat;
        background-image: url("../img/homepage/Bg.png");
        background-size: cover;
    }

    .home-banner-text {
        font-family: 'HK Grotesk', sans-serif;
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        margin-top: -1.3em;
        line-height: 42px;
        text-align: center;

    }


    .accelerate-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        padding: 30px 10px;
        text-align: center;

    }


    .vegan-card-title {
        font-family: 'HK Grotesk', sans-serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        text-align: left;


    }

    .home-card-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 19px;
        font-style: normal;
        line-height: 26px;
        text-align: center;

    }

    .vegan-card-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        text-align: left;

    }

    .vegan-card-title-2 {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        text-align: center;
    }

    .card-bottom-border {
        background: #E0806F;
        margin-top: 15px;
        border-radius: 4px;
        height: 4px;
        display: inline-flex;
        line-height: 60px;
        flex-direction: column-reverse;
    }

    .subscription-popup .MuiDialog-paper {
        margin: 20px 20px 35px 20px !important;
    }

}

@media (max-width: 600px) {
    .vegan-cards > div {
        width: 100%;
    }

    .vegan-cards img {
        width: 100%;
        height: 213px;
        object-fit: cover;
    }

    .accelerate-icon > img {
        padding-top: 30px;
    }

    .about-cards > div {
        width: 91%;
    }

    .accelerate-card {
        padding: 133px 14px 70px;
    }

    .card-position-up-section {
        top: -320px;
        margin-bottom: -290px;
    }

    .header-small-section {
        min-height: 340px;
    }

    .header-section {
        min-height: 560px;
    }

    .card-position-section {
        top: -95px;
        margin-bottom: -80px;
    }

    .item-headings {
        font-size: 24px;
        line-height: 1.3;
    }
}



.btn-text-white .MuiButtonBase-root
{
    color: #ffffff !important;
    border: 2px solid #ffffff !important;
    /*background-color: #003f61 !important;*/
    /*border-radius: 40px 0 !important;*/
    box-shadow: -5px 4px 0 #f3f3f3 !important;
    padding: 5px 25px !important;
    font-weight: normal !important;

}

@media (max-width: 992px) {
    .factsheet-btn .MuiButtonBase-root {
        display: block;
        width: 100%;

    }
}

