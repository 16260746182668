.drawer-menu-icon {
    width: 25px;
    height: 25px;
}

.items-images {
    max-width: 130px;
    display: block;
    margin: 0 auto;
    padding: 22px 10px 10px 0;
}

.platform-cards-border {
    border-radius: 25px !important;
}


.slogan-line {
    font-size: 20px;
    background-color: #efefef;
    font-weight: 500;
    line-height: 1.2;
}

.item-headings {
    margin-bottom: 18px;
    font-size: 30px;
    font-weight: 600;
    font-family: 'HK Grotesk', sans-serif;
}

.item-headings > div:first-of-type {
    display: flex;
    align-items: center;
}

.item-headings > div:first-of-type > img {
    margin-right: 10px;
}

button:focus {
    outline: 0 !important;
}

.auth-btn {
    font-weight: bold;
    padding: 8px 30px !important;
    width: 100%;
    margin-top: 20px;
    font-size: 20px !important;
    border-radius: 50px;
    color: white !important;
    text-transform: unset !important;
    font-family: 'Source Sans Pro', sans-serif !important;
}


.feature-card {
    background-color: #003f61 !important;
    border-radius: 12px !important;
    cursor: pointer;
}

.feature-card img {
    max-width: 80px;
    height: 85px;
    display: block;
    margin: 0 auto;
    padding-top: 18px;

}

.feature-card:hover {
    transform: scale(1.02);

}

.box-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px !important;
}

.shadow-img:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .5), 0 4px 15px 0 rgba(0, 0, 0, .5);
    will-change: opacity, transform;
    transition: all .3s ease-out;
}

.shadow-img {
    border: 0;
    cursor: pointer;
}

.disabled-img {
    -webkit-filter: grayscale(100%) brightness(58%) contrast(777%);
    opacity: .3;
}

.ba-thumbnail img {
    object-fit: cover;
    width: 100%;
    max-height: 300px;
}

.font-red {
    color: red !important;
}

.feature-card-content {
    margin-bottom: 6px;
    font-weight: 400;
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;

}

.bg-light-blue {
    background-color: #265c79 !important;
}

.share-icon {
    width: 40px;
    height: 40px;
    margin-right: 30px;
}

.share-text {
    /*min-width: 350px;*/
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.share-option-text {
    font-size: 20px;
    font-weight: bold;
    color: #003f61;
    font-family: 'Fira Sans', sans-serif;
    cursor: pointer;
}


@media (max-width: 767px) {
    .slogan-line {
        font-size: 16px;
    }
}

.map-close-btn {
    z-index: 10000;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    padding: 8px 11px;
    position: absolute;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    top: 60px;
    right: 10px;
}

/*scroll to top css*/
.scroll-top-wrapper {
    position: fixed;
    z-index: 10001;
    bottom: 65px;
    right: 30px;
    cursor: pointer;
    background: black;
    opacity: 0.3;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    transition: 2s ease-in;
}

.scroll-icon {
    font-size: 30px;
    position: absolute;
    /* font-weight: bold; */
    color: white;
    text-align: center;
    width: 32px;
}

@media (max-width: 500px) {
    .scroll-top-wrapper {
        right: 10px;
    }

    .card-hover:hover {
        box-shadow: none !important;
    }

    .xs-padding-10 {
        padding: 10px 5px !important;
    }

}

/*end of scroll to top css*/
/*homepage features css*/

.activity-card-text {
    padding: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 17px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Fira Sans", sans-serif;
    flex-basis: 23%;
    flex-grow: 1;
}

.activity-icon img {
    height: 55px;
    width: auto;
    margin: 10px 0;
    object-fit: fill;
}

.activity-card-text a {
    color: #003f61 !important;
}


/*end of homefeatures css*/

/*video panels css start*/

.image-video {
    position: relative;
    padding-bottom: 56.2%;
}

.image-video img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.video-play-btn {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: white;
    font-size: 15px;
    background: rgba(0, 0, 0, .6);
    padding: 10px 12px;
    border-radius: 50px;
}

.bottom-icons {
    position: absolute;
    bottom: 6%;
    left: 5%;
    width: 90%;
}

/*video panels css end*/

/*partner panel css starts*/

.btn-green {
    border: 1.5px solid #5DB77A;
    color: #5DB77A;
    background-color: white;
}


.btn-green:hover {
    background-color: #5DB77A;
    color: white;
}

.card-height {
    height: calc(100% - 12px);
    border-radius: 12px !important;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.social-media-image img {
    width: 40px;
    height: 40px;
}

.bottom-bar-fixed {
    margin-top: 56px;
}

.appbar-icon {
    font-size: 28px !important;
}

.appbar-big-icon {
    font-size: 50px !important;
}

.category-card-height {
    background: #003f61 !important;
    height: calc(100% - 12px);
    border-radius: 12px !important;
}

.ba-platform-text {
    padding: 40px 70px 60px;
}

.ba-platform-card {
    margin-top: -30px;
}


@media (max-width: 767px) {
    .slogan-line {
        font-size: 16px;
    }

    .ba-platform-text {
        padding: 20px 20px 30px 20px;
    }

    .ba-platform-card {
        margin-top: -20px;
    }

    .ba-platform-text > h1 {
        font-size: 20px;
        font-weight: 700 !important;
    }

    .ba-platform-text > h5 {
        font-size: 15px;
    }
}

/*rest app bar input filed*/
.search-input {
    flex: 1 0;
    background-color: #334b63;
    border-radius: 50px;
    border-color: #334b63;
    padding: 3px 12px 3px 15px;
    color: white !important;
    max-width: 40%;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

@media only screen and (max-width: 800px) {
    .search-input {
        width: auto !important;
        max-width: 100%;
    }
}


.ba-drawer .MuiDrawer-paper {
    overflow-x: hidden !important;
}


.custom-label {
    margin-bottom: 0 !important;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    text-shadow: none !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .18);
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    display: inline;
    border-radius: .25em;
    text-transform: uppercase;
    padding: 2px 6px 2px;
}


.label-warning {
    background-color: #F1C40F;

}

.label-primary {
    background-color: #337ab7;
}

.label-danger {
    background-color: #ed6b75;
}

.zoom-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: .7;
    transition: opacity .2s;
}

.zoom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #ffffff;
    cursor: pointer;
    border-radius: 50%;
}

.zoom-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #ffffff;
    cursor: pointer;
    border-radius: 50%;
}

.modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    background-color: white;
    padding: 10px;
    outline: none;
    border-radius: 4px;
    /*width: 70%;*/
    min-width: 70%;
    min-height: 80%;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 767.98px) {
    .modal-wrapper {
        min-height: 70%;
        min-width: 90%;
    }
}


.bottom-bar-fixed {
    margin-top: 56px;
}

.appbar-icon {
    font-size: 28px !important;
}


.text-style-none {
    text-transform: none !important;
}

span.chevron-right {
    background: #f3f4f6;
    padding: 4px 16px 7px 17px;
    border-radius: 50%;
    font-size: 20px;
}


/*sustainable Fintech Css starts*/

.line-height-17 {
    line-height: 17px;
}

.detail-banner {
    margin-left: -15px;
    margin-right: -15px;
}

.margin-top--20 {
    margin-top: -20px !important;
}

.banner {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -4px;
}

.section1-container {
    height: 442px;
    display: table-cell;
    vertical-align: middle;
}


.ba-co-text-style {
    font-size: 18px;
    color: #233c60;
    /*font-family: 'Open Sans', sans-serif;*/
    line-height: 1.4em;
    /*font-weight: 400;*/


}

.ba-co-btn button {
    padding: 9px 35px;
    font-weight: 500;
    /*letter-spacing: 1px;*/
    /*font-family: 'Open Sans', sans-serif;*/
}

.heading-line {
    position: relative;
    line-height: 1.2em;
    font-weight: 400;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 70px;
    color: #0c3c60;

}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.ba-co-paragraph {
    line-height: 21px;
    font-size: 14px;
    letter-spacing: 0;
    /*font-family: 'Open Sans', sans-serif;*/

}

.ba-co-text-underline {
    line-height: 21px;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 500;
    cursor: pointer;
    font-style: italic;
    /*font-family: 'Open Sans', sans-serif;*/
    text-decoration: underline;
    outline-color: black;
}

.ba-co-client-card-text {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
}

.font-style-open-sans {
    font-family: 'Open Sans', sans-serif;
}


.ba-co-text-style {
    font-size: 18px;
    color: #233c60;
    /*font-family: 'Open Sans', sans-serif;*/
    line-height: 1.4em;
    /*font-weight: 400;*/


}

.ba-col-card-body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #003f61;
}

.ba-co-text-style a {
    color: #233c60 !important;
}

.ba-co-text-style span {

    /*font-family: 'Open Sans', sans-serif;*/
}

.ba-co-btn button {
    padding: 9px 35px;
    font-weight: 500;
    /*letter-spacing: 1px;*/
    /*font-family: 'Open Sans', sans-serif;*/
}

.ba-co-btn button:hover {
    opacity: 0.7;
}

.bg-co-client-image img {
    width: auto;
    height: 130px;
    object-fit: cover;
    border: 1px solid #003f61;
}

.section1-container {
    height: 442px;
    display: table-cell;
    vertical-align: middle;
}


.ba-col-card-body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #003f61;
}

.ba-co-client-card-text {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
}

.primary-background {
    background-color: #003F61 !important;
    color: white !important;
}

.grey-background {
    background-color: #d1d4d5 !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-blue {
    color: #003f61 !important;
}

.font-black {
    color: #000 !important;
}

.ba-co-title {
    /*line-height: 1.2em;*/
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 10px;
    font-family: 'Raleway', sans-serif;
}

.ba-co-lg-title {
    /*line-height: 1.2em;*/
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 3px;
}

.ba-co-text-color {
    color: #39729B;
}

@media (max-width: 576px) {
    .bg-sm-border-white {
        border-bottom: 2px solid #ffffff;
    }

    .bg-sm-border-blue {
        border-bottom: 2px solid #003f61;
    }
}

.banner-image {
    margin-top: -3px;
}

.text-underline {
    text-decoration: underline !important;
}

.header-on-cards {
    background: #003f61;
    padding: 8px 34px;
    letter-spacing: 1px;
    box-shadow: 0 6px 10px 6px rgb(84 84 84 / 10%), 0 1px 2px 1px rgb(84 84 84 / 10%), 0 1px 2px 1px rgb(84 84 84 / 10%);
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    font-weight: 400;
    /*font-family: 'Open Sans', sans-serif;*/
}

.cursor:hover {
    cursor: pointer !important;
}

.heading-line::before {
    position: absolute;
    content: '';
    width: 87px;
    height: 6px;
    background: #003f61;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.home-card-img img {
    object-position: top center;
    object-fit: cover;
    height: 'auto',
}

.heading-line {
    position: relative;
    line-height: 1.2em;
    font-weight: 400;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 70px;
    color: #0c3c60;

}

.heading-line-style {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: #003f61;
    justify-content: center;
    margin: 8px 0;
}

.font-21 {
    font-size: 21px;
    /*color:#003f61;*/
}

.font-size-32 {
    font-size: 32px !important;
}

.heading-line-style::before,
.heading-line-style::after {
    content: "";
    flex-grow: 1;
    background: #003f61;
    height: 3px;
    font-size: 0;
    line-height: 0;
    max-width: 20%;
    margin: 0 8px;
}

@media (max-width: 767.98px) {
    .heading-line {
        font-size: 45px;
    }
}


/*sustainable Fintech Css ends*/

.ba-secondary-color {
    background-color: #f08372 !important;
    color: white !important;
}


.custom-badge .MuiBadge-anchorOriginBottomLeftRectangle {
    left: -7px !important;
    bottom: 3px !important;
}

.navbar-icons {
    font-size: 28px !important;
    color: white;
    margin: 0 10px;
}


.card-feature-image img:first-of-type {
    height: 140px !important;
    flex-shrink: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
}

.card-feature-image div:first-of-type {
    height: 140px !important;
    margin-bottom: 0.5rem !important;
}

.text-red {
    color: #ff0000;
}

.error-block-refresh {
    background: rgba(199, 194, 194, 0.15) !important;
    color: #797373 !important;
    margin-top: -10px !important;
    margin-bottom: -10px !important;
    height: 30px;
    border-radius: 2px !important;

}

.error-block-close {
    color: #ff0000 !important;
    border-radius: 0 !important;
}

a:hover {
    color: inherit;
    text-decoration: inherit;
}

.feature-listing-card-ui img:first-of-type {
    max-height: 110px !important;
    width: 100% !important;
    margin-top: 2px !important;
}

.blur-text {
    filter: blur(3px);
}

/*Image Editor V2 Changes End*/

.cover-image-wrapper {
    position: relative;
    padding-bottom: 42.8%;
}

.edit-image-border-class .cover-image-wrapper {
    border: 2px solid #d9d9d9;
    border-radius: 3px;
}

.cover-image-wrapper img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    /*background-color: #003f61;*/
}

.cover-image-wrapper canvas {
    position: absolute !important;
    object-fit: cover !important;
    width: 100% !important;
    height: 100% !important;
    border: 2px dashed grey !important;
}

.coverImage-dialog-width .MuiDialog-paperWidthMd {
    width: 48% !important;
}

.editor-canvas {
    cursor: move !important;
    width: 300px !important;
    height: 300px !important;
    touch-action: none;
}


@media (max-width: 992px) {
    .coverImage-dialog-width .MuiDialog-paperWidthMd {
        width: 100% !important;
    }

    .editor-canvas-mobile {
        cursor: grab !important;
        width: 250px !important;
        height: 250px !important;
        touch-action: none;
    }
}

/*Image Editor V2 Changes End*/


.feed-label {
    background: #d1d4d5;
    color: #616263d9;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 5px;
    display: inline-block;
    border-radius: 2px;
    margin-bottom: 5px;
}


.feed-single-gallery .react-photo-gallery--gallery > div > img {
    width: 100%;
    object-fit: cover;
    height: inherit;
    max-height: 60vh;
    object-position: top center;
    overflow: hidden;
    background-color: #ffffff;
}

.gallery-img {
    width: 293.5px;
    height: 222.75px;
}

.user-feed-img .MuiAvatar-root {
    width: 48px;
    height: 48px;
}

.feed-viewport {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    overflow-x: hidden;
}

@media (max-width: 991.98px) {
    .feed-viewport {
        height: calc(100vh - 115px);
    }
}

.single-gallery .react-photo-gallery--gallery div:first-of-type {
    display: block !important;
}

.single-gallery .react-photo-gallery--gallery > div > img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 130vh;
    object-position: top center;
    overflow: hidden;
    background-color: #ffffff;
}

.react-images__blanket {
    background-color: black !important;
}

.inline-feed-location span:first-of-type {
    flex-grow: 0 !important;
}

.border-radius-20 {
    border-radius: 20px;
}

.event-promotion-title {
    font-size: 23px !important;
}

@media (max-width: 767.98px) {
    .event-promotion-title {
        font-size: 19px !important;
    }
}

.secondary-header {
    padding: 12px;
    background: #426b8e !important;
    /* background: linear-gradient(to right,#0097d7 , #0073b1) !important; */
    color: white;
    /*border-radius: 2px;*/
    letter-spacing: 1px;
}

.event-icon {
    width: 30px;
    height: 30px;
}


.stream-screen-height {
    height: calc(100vh - 20px);
}

.DlAccordionMargin .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 !important;
}


.font-18 {
    font-size: 18px;
}


/*booth video css starts*/

.max-height-60 {
    max-height: 60px;
}

.video-image-wrapper {
    position: relative;
    padding-bottom: 56.5%;
}


.video-image-wrapper canvas, .video-image-wrapper img, .video-image-wrapper video {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;

}


.video-uploader-border img, .video-uploader-border canvas {
    border: 2px dashed #707070;

}


.video-image-wrapper .dzu-dropzone > label {
    position: unset;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: flex-start;
}

.video-image-wrapper .dzu-dropzone {
    display: unset;
    position: unset;
    overflow: unset;
    margin: 0 !important;
    border: 0;
}

.video-image-wrapper .dzu-previewContainer {
    position: absolute;
    margin: 0 !important;
    border: 2px dashed #707070;
    height: 100%;
    min-height: unset;
}

.booth-video img {
    object-fit: cover;
    height: -webkit-fill-available !important;
    max-height: unset !important;
}

.booth-video-video-menu-option {
    background: rgba(0, 0, 0, 0.44);
    border-radius: 5px;
    padding: 7px;
    font-size: 16px;
}


.img-responsive-16by9 {
    display: block;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}


/*booth video css ends*/

.explore-banner-img {
    padding: 30px;
    margin-top: 30px;
    background-attachment: fixed;
    background-color: white;
    background-position: top;
    /*background-image: url("../../assets/img/homepage/Feed-btn-bg.jpg");*/
}


.font-grey {
    color: grey !important;
}

.live-status-bar {
    background-color: #d1d4d5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    min-height: 45px;
}


/* About us page css starts*/

.about-us-main-title {
    text-align: center;
    font-size: 65px;
    color: #000;
    padding: 60px 5px 20px 5px;
    font-weight: bolder;
    letter-spacing: 2px;
    line-height: 22px;
}

.about-us-paragraph-text {
    text-align: center;
    padding: 25px 5px 35px 5px;
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 30px;
}

.about-us-gradient-color {
    background-image: linear-gradient(to right, #185c6a, #2c776e);
}

.about-us-subtitle {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 28px;
    padding: 15px 0;
}

.about-us-text-size-18 {
    font-size: 18px;
}

.about-us-title {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 30px;
    padding: 30px 0;
    text-align: center;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
}

.about-us-line-text {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
}

.about-us-subtitle-text {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 24px;
    padding: 25px 80px;
    line-height: 35px;
}

@media (max-width: 776px) {
    .about-us-subtitle-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 24px;
        padding: 0;
        line-height: 35px;
    }
}

/* About us page css end*/


.stream-screen-height {
    height: calc(100vh - 20px);
}


.live-comment-content {
    overflow-y: scroll;
}

.live-frame-height {
    height: calc(100% - 65px);
}

.live-frame-height > div {
    height: 100%;
}

.session-timer {
    display: flex;
    min-width: 210px;
}

.coverImage-dialog-width .MuiDialog-paperWidthMd {
    width: 48% !important;
}

@media (max-width: 992px) {
    .coverImage-dialog-width .MuiDialog-paperWidthMd {
        width: 100% !important;
    }

    .live-frame-height {
        height: calc(100% - 90px);
    }

    .live-frame-height > div {
        height: 50%;
    }

    .live-comment-content {
        overflow-y: scroll;
        max-height: calc(100vh - 190px);
    }
}

/*News Image css*/
.news-detail-container > img {
    max-width: 100%;
    height: auto;
}


/*BULK EMAIL BUSINESS CSS*/

.email-edit-text {
    color: #5db77a;
    font-weight: 600;
    background-color: #d4fce0;
    padding: 2px;
}

.group-btn-custom .MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
    margin-left: 0 !important;
}

.group-btn-custom .MuiButton-outlined.focus-color {
    border: 1px solid #003f61 !important;
    color: #003f61 !important;
    font-weight: bold !important;
}

.dot-circle {
    height: 5px;
    width: 5px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
}

.dealDashboard-iframe {
    height: calc(100vh - 120px);
}

@media (max-width: 991.98px) {
    .dealDashboard-iframe {
        height: calc(100vh - 175px);
    }
}


.font-small {
    font-size: 12px;
}

.notification {
    padding: 10px;
    font-size: 16px;
    min-height: 70px;
    line-height: 1.8;
}

.card-hover {
    border-bottom: 1px solid #e0dbdb;
}

.business-gallery-item {
    height: 100%;
}

.business-gallery-item > div:first-child {
    max-height: 70%;
    height: 70%;
    display: flex;
}


.network-profile-filter > div > label {
    margin-bottom: 0;
}

.bg-light-blue-unsub {
    background-color: #f3f3f3;
}

.sponsor-cards-width {
    width: 50%;
}

.sponsors-img > div > img {
    min-height: 90px !important;
    max-height: 90px !important;
    flex-shrink: 0 !important;
}

.session-item-expansion .MuiExpansionPanelSummary-content {
    margin: 0 !important;
}

.session-item-expansion .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 0 !important;
}

.session-item-expansion .MuiExpansionPanelDetails-root {
    padding: 0 !important;
}

.session-item-expansion > div {
    cursor: default !important;
}

.review-email-scroll {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 29em);
    overflow-y: scroll;
}

.investor-plan-card {
    border-radius: 20px 3px 41px 3px;
    box-shadow: 0 0 10px 0 #0000001a;
    transition: box-shadow 500ms;
    border-bottom: 2px solid #003f61;
}

.preview-gallery .react-photo-gallery--gallery > div > img {
    cursor: unset !important;
}

.uploader-image > div > img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 5px !important;
    /*object-fit: cover !important;*/
}

.active-link > div {
    background-color: #5db77a;
    color: white !important;
}

.active-link span {
    color: white !important;
}

.nav-accordian-icon .MuiAccordionSummary-expandIcon {
    margin-right: 8px;
}

.nav-accordian-icon .MuiAccordionSummary-root, .nav-accordian-icon .MuiAccordion-root, .appbar-accordian .MuiAccordion-root, .appbar-accordian .MuiAccordionSummary-root {
    margin-bottom: 0 !important;
}

.resource-preview-item {
    z-index: 1000;
}

.resource-preview-item-desc > p {
    display: initial;
}


.resource-preview-item #resourcePreview-item-list-item {
    margin-bottom: 0 !important;
}


.deal-switch-setting > div > div > legend {
    min-width: 170px;
}

.scroll {
    overflow-y: auto;
    width: 240px;
}

.scroll::-webkit-scrollbar {
    width: 8px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}

.group-rules > div > div > .MuiInputBase-adornedStart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.navbar-ios-back-icon {
    font-size: 20px;
    width: 22px;
    text-align: center;
    padding-top: 5px;
    margin-right: 5px;
}

.ck.ck-mentions > .ck-list__item > .ck-on {
    background-color: #003f61 !important;
}

.ck.ck-mentions > .ck-list__item > .ck-on > span {
    color: #ffffff !important;
}

.left-div {
    width: 30px;
    vertical-align: top;
    text-align: right;
}

.right-div {
    width: calc(100% - 30px);
}

.resource-preview-item .dl-list-title:hover {
    text-decoration-line: none !important;
}

.css-1rkjyjh {
    opacity: 1
}

.thumbnail-photo {
    position: absolute;
    display: inline-flex;
    width: 100%;
    left: 0;
    text-align: center;
    bottom: -65px;
    height: 135px;
    justify-content: center;
}

.cover-img-bottom-margin {
    margin-bottom: 80px;
}

.thumbnail-photo > img {
    max-width: 135px;
    border-radius: 10px;
    padding: 6px;
    background: white;
    max-height: 135px;
    position: unset !important;
    object-fit: contain !important;
}

.news-panel-container img {
    width: 100% !important;
    object-fit: contain !important;
    height: auto !important;
}

.news-panel-container figure {
    max-width: 100% !important;
}

.dl-component-label-review {
    font-weight: 400 !important;
    line-height: 1 !important;
    font-size: 13px !important;
    margin-bottom: 8px !important;
    color: #656565
}

.dl-chip-input .MuiInputBase-root.MuiOutlinedInput-root {
    padding-top: 7px !important;
}

/*release-1.24 conference video patch start*/

.min-height-300 {
    min-height: 300px;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.2%;
}


.videoWrapper video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    border-radius: 4px 0;
    height: 100%;
}

/*release-1.24 conference video patch end*/

.faq-container, .faq-container .MuiAccordion-root, .faq-container .MuiAccordionSummary-root {
    border-radius: 15px !important;
}

.faq-container .MuiAccordionSummary-root {
    height: auto !important;
    min-height: 70px;
}


.negative-container-fluid {
    margin-left: -15px;
    margin-right: -15px;
}

@media (max-width: 767px) {
    .negative-container-fluid {
        margin-left: -8px;
        margin-right: -8px;
    }
}

.ad-banner {
    position: relative;
    padding-bottom: 83.33%;
}

.ad-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.ad-banner .ad-text {
    max-width: 100%;
    position: absolute;
    top: 0
}

.feature-list:hover {
    background-color: #e4e6e9;
}

/*2.03 change merged*/
.ba-bg-success-color {
    background-color: #55bc84;
}

.white-space-prewrap {
    white-space: pre-wrap;
}

.post-image-wrapper {
    position: relative;
    padding-bottom: 42.8%;
}


.post-image-wrapper img {
    position: absolute;
    object-fit: contain;
    width: 100%;
    padding: 10px;
    height: 100%;
}


.video-js .vjs-big-play-button {
    top: 45% !important;
    left: 45% !important;
}

/*Starts css for prelogin app header native app link buttons*/
.header-app-links-wrapper {
    flex:1;
    display: flex !important;
    justify-content: flex-end;
}

.header-app-links {
    max-width: 135px;
    margin: 3px;
    cursor: pointer;
}

@media (max-width: 1095px) {
    .header-app-links {
        max-width: 110px;
        margin: 1px
    }
}

@media (max-width: 1045px) {
    .header-app-links {
        max-width: 92px;
        margin: 1px
    }
}
/*Ends css for prelogin app header native app link buttons*/
