
body {
    /*font-family: 'Fira Sans', sans-serif;*/
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: normal;
}

.snackbar-info .MuiSnackbarContent-root {
    background-color: #003f61 !important;
}

.snackbar-success .MuiSnackbarContent-root {
    background-color: #5db77a !important;
}

.snackbar-warning .MuiSnackbarContent-root {
    background-color: #f08372 !important;
}

.snackbar-danger .MuiSnackbarContent-root {
    background-color: #d32f2f !important;
}


.row > [class^="col-"], .row > [class*=" col-"] {
    padding-left: 8px;
    padding-right: 8px;
}

.row {
    margin-left: -8px !important;
    margin-right: -8px !important;
}

.container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

a:hover {
    color: #5db77a;
    text-decoration: none;
}

a {
    color: #5db77a !important;
}

.link {
    cursor: pointer;
}

.MuiSelect-select {
    min-width: 120px !important;
    text-align: left !important;
    font-size: 14px !important;
}

.negative-container-fluid {
    margin-left: -15px ;
    margin-right: -15px;
}

.ba-green-color
{
    color:#5db77a;
}
.ba-primary-color
{
    color:#003f61;
}
.font-family-roboto
{
    font-family: 'Roboto', sans-serif ;
}

.font-family-grotesk {
    font-family: 'HK Grotesk', sans-serif !important;
}


@media (max-width: 767px) {
    .container-fluid {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .negative-container-fluid {
        margin-left: -8px;
        margin-right: -8px;
    }

}


.ba-bg-primary-color {
    background-color: #003f61 !important;
}

.ba-green-color {
    color: #5db77a;
}

.ba-primary-color {
    color: #003f61;
}


.MuiExpansionPanelDetails-root{
    padding: 10px 0 0 0 !important;
}


/*dropzone css */
/*TODO:Confirm with ashu*/

.default-img-preview {
    opacity: .8;
    width: auto !important;
    height: -webkit-fill-available !important;
}

.dzu-dropzone {
    overflow: hidden !important;
    min-height: 130px !important;
}

.preview-edit-btn {
    background: lightgray;
    color: white;
    position: absolute;
    right: 5px;
    top: 4px;
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 50%;
}

.preview-text {
    background: rgba(0, 0, 0, 0.44);
    border-radius: 5px;
    padding: 11px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: white;
    line-height: 18px;
    font-family: 'HK Grotesk';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.preview-remove-btn {
    background: lightgray;
    color: white;
    font-size: 16px;
    position: absolute;
    left: 5px;
    top: 4px;
    padding: 4px 11px;
    border-radius: 50%;
}


.dzu-dropzone > label {
    margin: 0;
    padding: 3px;
}

.dzu-dropzone > div:first-child {
    display: block !important;
    margin-top: 50px;
    font-size: 22px;
}

.dropzone-preview {
    color: white;
    width: 100%;
    height: -webkit-fill-available;
}

.dzu-sync-multiple > div:first-child {
    display: inline-block !important;
}

.dzu-sync-multiple > div > .dzu-inputLabelWithFiles {
    display: inline-block !important;
    padding: 8px !important;
    margin-right: 8px !important;
    font-size: 20px !important;
    color: white !important;
    background-color: #003f61 !important;
    font-family: 'Roboto', sans-serif !important;
    margin-top: 43px;
    width: 45%;
}

/*dropzone css ends */

/*comment css start*/
.ck-dropdown__arrow {
    z-index: 0 !important;
}

.ck-balloon-panel_toolbar_west {
    display: none !important;
}

.ck-editor__editable.ck-focused
{
    box-shadow: none !important;
}

.ck-read-only
{
    border:none !important;
    padding:1.2px !important;

}

.ck-read-only > p:first-of-type
{
    margin-top: 0 !important;
}
.ck-read-only > figure:first-of-type
{
    margin-top: 0 !important;
}

.ck-read-only > p:last-of-type
{
    margin-bottom: 0 !important;
}

/*comment css end*/


/*.MuiTypography-root * {*/
/*    font-family: "Fira Sans", sans-serif !important;*/
/*}*/

.prod-theme-bg {
    background: #001e3c !important;
    color: white;
}

.qa-theme-bg {
    background: #fec763 !important;
    /*background: #001e3c !important;*/
    color: white;
}

.dev-theme-bg {
    background: #f1b0b7 !important;
    color: white;
}


.MuiPickersToolbar-toolbar {
    padding: 10px 33px !important;
}

.MuiPickersModal-dialogRoot {
    border-radius: 20px !important;
}

/*.MuiPickersBasePicker-container .MuiTypography-subtitle1*/
/*{*/
/*    display: none !important;*/
/*    min-width: 0 !important;*/
/*}*/


.MuiButton-root {
    text-transform: capitalize !important;
}

.react-images__view > img
{
    max-height: 100vh !important;
}

