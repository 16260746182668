
.dl-screen-header {
    margin-top: 0;
}

.dl-list-title {
    color: rgba(0, 0, 0, 0.9) !important;
    font-family: 'HK Grotesk', sans-serif !important;
    font-size: 22px;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 600;
    /*max-height: 25px;*/
    line-height: 25px;
    -webkit-line-clamp: 1;
    margin-bottom: 15px;
    /*word-break: break-all;*/
}

.dl-list-title:hover {
    text-decoration-line: underline;
}

.dl-document-uploader > div {
    border: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.dl-document-uploader > div > .dzu-inputLabelWithFiles {
    /*display: inline-block !important;*/
    padding: 8px !important;
    margin-right: 8px !important;
    font-size: 20px !important;
    color: #fff !important;
    background-color: #003f61 !important;
    margin-top: 43px;
    width: 45%;
    display: none !important;
}


.dl-app-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
}

.dl-app-loader {
    border: 10px solid #073f61;
    border-radius: 50%;
    border-top: 10px solid #f3f3f3;
    width: 80px;
    height: 80px;
    opacity:0.8;
    -webkit-animation: apploader 2s linear infinite; /* Safari */
    animation: apploader 2s linear infinite;
    z-index: 9999;
}

.dl-panel-card {
    overflow: inherit !important;
}

.dl-panel-card > div {
    border-radius: 5px 5px 0 0 !important;
}

.dl-panel-title {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 22px;
    line-height: normal;
    color: #334b63 !important;
}

.dl-panel-footer-content {
    margin: 0 -20px ;
}

.dl-panel-footer-content > button {
    width: -webkit-fill-available;
    padding: 10px;
}



/* Safari */
@-webkit-keyframes apploader {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes apploader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 992px) {
    .dl-list-title {
        /*max-height: 34px;*/
        line-height: 25px;
        -webkit-line-clamp: 2;
    }
}

.dl-component-label {
    font-weight: 400 !important;
    line-height: 1 !important;
    font-size: 13px !important;
    margin-bottom: 8px !important;
    color: #656565 !important;
}

.dl-textfield-label {
    font-weight: 400 !important;
    line-height: 1 !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
    color: #656565 !important;
}

.dl-switch-label {
    font-weight: 400 !important;
    line-height: 1 !important;
    font-size: 16px !important;
    margin-bottom: 0 !important;
    color: #656565 !important;
    margin-right: 25px;
    width: auto;
}

.dl-radio-component-label {
    font-weight: 400 !important;
    line-height: 1 !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
    color: #656565 !important;
}

/*.ck-read-only {*/
/*    padding: 0 !important;*/
/*    border:none !important;*/
/*}*/

.dl-richtext-error > .dl-component-label {
    color: #f44336;
}

.dl-richtext-error > .ck-editor > .ck-editor__top > .ck-sticky-panel > .ck-sticky-panel__content > .ck-toolbar {
    border-color: #f44336 !important;
    border-bottom-color: #c4c4c4 !important;
}
.dl-richtext-error > .ck-editor > .ck-editor__main > .ck-content {
    border-color: #f44336 !important;
    border-top-color: #c4c4c4 !important;
}

.dl-rounded-textfield > .MuiOutlinedInput-root {
    border-radius: 22px;
}
.dl-rounded-textfield > .MuiOutlinedInput-root > .MuiOutlinedInput-input {
    padding: 10px 14px;
}

.dl-border-bottom-textfield {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.cursor {
    cursor: pointer;
}

.dl-menu-options-height div:not(:last-of-type) {
    height: 45px;
}

.dl-badge-primary {
    font-size: 12px !important;
    background-color: #003f61;
    color: white;
}

.dl-badge-secondary {
    font-size: 12px !important;
    background-color: #b7bbbf;
    color: #494646;
}

.dl-badge-danger {
    font-size: 12px !important;
    background-color: #dc3545;
    color: #ffffff;
}

.dl-engagement-point {
    display: inline-flex;
    color: rgb(0, 0, 0);
    flex-direction: column;
    align-items: center;
    /*min-width: 45px;*/
    justify-content: flex-start;
}

.dl-engagement-panel-container  .dl-engagement-point > i {
    font-size: 18px;
    cursor: pointer;
    /*padding: 13px;*/
    /*padding: 14px;*/
}

.dl-engagement-point > i {
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 8px;
}

.dl-engagement-point > div {
    margin-top: 2px;
    /*padding: 0 13px 8px;*/
    /*padding: 14px;*/
}

.dl-engagement-point-disabled > div {
    margin-top: 2px;
    /*padding: 14px;*/
}

.dl-engagement-point-disabled {
    display: inline-flex;
    color: #d3d3d3;
    flex-direction: column;
    align-items: center;
    cursor: auto;
    justify-content: flex-start;


}
.dl-engagement-panel-container .dl-engagement-point-disabled > i {
    color: #d3d3d3 !important;
    font-size: 18px;
    cursor: auto;
    /*padding: 14px;*/
}
.dl-engagement-point-disabled > i {
    font-size: 18px;
    margin-bottom: 8px;
}
/*Scroll Bar Slim*/
.scrollbar-slim::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    overflow-x: hidden;
    border-radius: 5px
}

@media (min-width: 767px) {
    .scrollbar-slim::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
        overflow-x: hidden;
    }
}

.scrollbar-slim::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 5px;
    overflow-x: hidden;

}

@media (max-width: 767px) {
    .dl-list-title {
        font-size: 18px;
    }
    .scrollbar-slim::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
        overflow-x: hidden;
    }

    .dl-engagement-panel-container  .dl-engagement-point > i {
        padding: 14px;
    }

    .dl-engagement-point > div {
        margin-top: 0;
        padding: 14px;
    }

    .dl-engagement-point-disabled > div {
        margin-top: 0;
        padding: 14px;
    }

    .dl-engagement-panel-container .dl-engagement-point-disabled > i {
        padding: 14px;
    }
}

/*Scroll Bar Slim*/

.dl-screen-title {
    font-size: 22px;
    letter-spacing: 0;
    color: #334b63;
    font-family: 'HK Grotesk', sans-serif ;
}

.dl-title-hover:hover {
    text-decoration: underline ;
    text-decoration-color: #334b63;
}


@media (max-width: 767px) {

    .dlTextView-key-value {
        flex-flow: column;
    }
    .dl-screen-title {
        font-size: 20px;
    }
}

.dl-chk-on-switch, .dl-cross-on-switch {
    position: absolute;
    font-size: 12px;
}

.dl-chk-on-switch {
    -webkit-transform: translate(33px,-6px);
    transform: translate(33px,-6px);
}

.dl-cross-on-switch {
    -webkit-transform: translate(15px,-6px);
    transform: translate(15px,-6px);
}
.dl-text-tranform-none {
    text-transform: none !important;
}

.dl-tabs .MuiTabs-scrollButtons {
    width: 30px !important;
}


/*STARTS css for lazy load min height*/

.dl-lazy-load-5{
    min-height: 5px;
}
.dl-lazy-load-10{
    min-height: 10px;
}
.dl-lazy-load-20{
    min-height: 20px;
}
.dl-lazy-load-50{
    min-height: 50px;
}
.dl-lazy-load-75{
    min-height: 75px;
}
.dl-lazy-load-100{
    min-height: 100px;
}
.dl-lazy-load-150{
    min-height: 150px;
}
.dl-lazy-load-200{
    min-height: 200px;
}
.dl-lazy-load-300{
    min-height: 300px;
}
/*ENDS css for lazy load min height*/
.max-lines-1 {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 20px;
    line-height: 20px;
    -webkit-line-clamp: 1;
}

.max-lines-1-fixed {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 20px;
    line-height: 20px;
    -webkit-line-clamp: 1;
}

.max-lines-2 {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    /*max-height: 40px;*/
    /*line-height: 20px;*/
    -webkit-line-clamp: 2;
}

.max-lines-2-fixed {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 40px;
    line-height: 20px;
    -webkit-line-clamp: 2;
}

.max-lines-3 {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 60px;
    line-height: 20px;
    -webkit-line-clamp: 3;
}

.max-lines-3-fixed {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 60px;
    line-height: 20px;
    -webkit-line-clamp: 3;
}

.max-lines-4 {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 80px;
    line-height: 20px;
    -webkit-line-clamp: 4;
}

.max-lines-4-fixed {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 80px;
    line-height: 20px;
    -webkit-line-clamp: 4;
}

.max-lines-5 {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 100px;
    line-height: 20px;
    -webkit-line-clamp: 5;
}

.max-lines-5-fixed {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 100px;
    line-height: 20px;
    -webkit-line-clamp: 5;
}

.max-lines-10 {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 200px;
    line-height: 20px;
    -webkit-line-clamp: 10;
}

.max-lines-10-fixed {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    height: 200px;
    line-height: 20px;
    -webkit-line-clamp: 10;
}

.react-photo-gallery--gallery>div>img {
    background: #000;
    object-fit: contain;
}


.unselected-chip {
    border: 1px solid #c8cbcf;
    background-color: #c8cbcf !important;
    padding: 5px 10px !important;
    color: white;
    font-size: 12px;
}

.selected-chip {
    border: 1px solid #003f61;
    background-color: #003f61 !important;
    padding: 5px 10px !important;
    font-size: 12px;
}

.ba-inline-editor-write {
    border: none!important;
    line-height: 1.1;
}

.dot-limiter {
    font-size: 5px;
    vertical-align: middle;
    padding: 0 9px 0 9px;;
    color: lightgray;
}

.comment-area {
    width: 100%;
    background: #f3f3f3;
    padding: 5px 10px;
    border-radius: 0 10px 10px 10px;
    margin-bottom: 5px;
}
.address-spl-option {
    display: flex;
    color: #5DB77A;
}

.disabled-chip {
    opacity: 0.5;
    pointer-events: none;
}

.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
}

.css-1ksj1k3-indicatorContainer {
    color: unset !important;
}

.category-disabled .css-1ksj1k3-indicatorContainer {
    color: #f0f0f0 !important;
}


.dl-date-picker > div > input {
    padding: 10.5px 0 10.5px 14px;
}
.dl-time-picker > div > input {
    padding: 10.5px 14px;
}

/*used only for streamlining the container within dlpanels */
.dl-pnl-padding > .dl-pnl-container-padding {
    padding: 1rem 0 !important;
}

.dl-snackbar > .MuiSnackbarContent-message {
    width: 100%;
}

.dl-comment-input p {
    word-break: break-all;
}

.dl-document-uploader .dzu-inputLabel{
    font-family: 'HK Grotesk', "sans-serif";
    font-weight: bold;
}
